export const appPermissions = {
  requestingAppId: 'nushu-native-web',
  requestedPermissions: [
    {
      streamId: ':system:email',
      level: 'read',
      defaultName: 'Email'
    },
    {
      streamId: ':system:affiliation',
      level: 'read',
      defaultName: 'Affiliation'
    },
    {
      streamId: 'profile',
      level: 'contribute',
      defaultName: 'Profile'
    },
    {
      streamId: 'patient-accesses',
      level: 'contribute',
      defaultName: 'Patient Accesses'
    },
    {
      streamId: 'comparisons',
      level: 'contribute',
      defaultName: 'Comparisons'
    }
  ],
};
