import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { App } from './container/App.js';
import { AppProvider } from './context/AppContext';
import theme from './styles/theme.js';

import './index.css';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

const rootElement = document.getElementById('root');
ReactDOM.render(
  <BrowserRouter basename={`/${process.env.REACT_APP_NAME}`}>
    <AppProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AppProvider>
  </BrowserRouter>,
  rootElement
);
