import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { FaRulerHorizontal, FaWalking } from 'react-icons/fa';

export const gaitActivityTypes = [
  {
    id: 'generic',
    shorthand: '-',
    title: 'Generic',
    category: 'free walks'
  },
  {
    id: '3min-test',
    shorthand: "3'",
    title: '3min',
    category: 'free walks'
  },
  {
    id: '6min-test',
    shorthand: "6'",
    title: '6min',
    category: 'free walks'
  },
  {
    id: 'timed',
    shorthand: 'T',
    title: 'Timed',
    category: 'free walks'
  },
  {
    id: 'auto-logging',
    shorthand: 'AL',
    title: 'Auto-logging',
    category: 'free walks'
  },
  {
    id: '4m-walk',
    shorthand: '4m',
    title: '4m Test',
    category: '4 & 10m tests'
  },
  {
    id: 'dual-task-4m-walk',
    shorthand: '4-DT',
    title: '4m Dual Task',
    category: '4 & 10m tests'
  },
  {
    id: 'static-4m-walk',
    shorthand: '4-S',
    title: 'Static 4m Walk',
    category: '4 & 10m tests'
  },
  {
    id: '10m-walk',
    shorthand: '10m',
    title: '10m Test',
    category: '4 & 10m tests'
  },
  {
    id: 'dual-task-10m-walk',
    shorthand: '10-DT',
    title: '10m Dual Task',
    category: '4 & 10m tests'
  },
  {
    id: 'static-10m-walk',
    shorthand: '10-S',
    title: 'Static 10m Walk',
    category: '4 & 10m tests'
  },
  {
    id: '25ft-walk',
    shorthand: '25ft',
    title: '25ft Test',
    category: '4 & 10m tests'
  },
  {
    id: 'tug',
    shorthand: 'TG',
    title: 'Timed-Up-&-Go',
    category: 'Miscellenous'
  },
  {
    id: 'stair-climb',
    shorthand: 'SC',
    title: 'Stair Climb',
    category: 'Miscellenous'
  },
];

for (const gat of gaitActivityTypes) {
  gat.resultType = ['result-magnes/nushu-v3']
}

export const nonGaitActivityTypes = [
  {
    id: 'posturography',
    shorthand: 'n/a',
    title: 'Posturography',
    category: 'Non-Gait',
    resultType: ['result-magnes/posturography-v2', 'result-magnes/posturography-v1']
  },
  {
    id: 'simple-haptic-reaction-time',
    shorthand: 'n/a',
    title: 'Simple Haptic Reaction Time',
    category: 'Non-Gait',
    resultType: ['result-magnes/reaction-time-v1']
  },
  {
    id: 'choice-haptic-reaction-time',
    shorthand: 'n/a',
    title: 'Choice Haptic Reaction Time',
    category: 'Non-Gait',
    resultType: ['result-magnes/reaction-time-v1']
  },
  {
    id: 'heel-raises',
    shorthand: 'n/a',
    title: 'Heel Raises',
    category: 'Non-Gait',
    resultType: ['result-magnes/heel-raises-v1']
  },
  {
    id: 'toe-tapping',
    shorthand: 'n/a',
    title: 'Toe Tapping',
    category: 'Non-Gait',
    resultType: ['result-magnes/toe-tapping-v1']
  }
]


export const allActivityTypes = [
  ...gaitActivityTypes,
  ...nonGaitActivityTypes,
]


let allActivityResultTypes = new Set()
for (const at of allActivityTypes) {
  for (const rt of at.resultType) {
    allActivityResultTypes.add(rt)
  }
}
export const activityResultTypes = Array.from(allActivityResultTypes)

export const category2IconMap = {
  'free walks': FaWalking,
  '4 & 10m tests': FaRulerHorizontal,
  Miscellenous: BiDotsHorizontalRounded
};

export const id2TitleMap = (iid) => {
  try {
    return allActivityTypes.filter((at) => at.id === iid)[0].title;
  } catch (e) {
    return iid;
  }
};
