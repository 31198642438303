import React, {Fragment, useState} from 'react';
import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';

import { useGlobalContext } from '../../../context/AppContext';
import { appPermissions } from '../../../data/appPermissions.js';
import doctorSetupCalls from '../../../definitions/doctorSetupCalls.json';
import patterns from '../../../definitions/patterns.js'
import { generateRandomString } from '../../../utils/auxFunctions.js';

const ERROR_TMPLT = {
  user: '',
  password: '',
}

/**
 * Renders the "MLoginForm" component.
 * @return {jsx} The about page component.
 */
export const MLoginForm = ({domain, postLogin}) => {
  const { setDocEndpoint } = useGlobalContext()
  
  const [error, setError] = useState(ERROR_TMPLT)
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [isSigningIn, setSigningIn] = useState(false)


  const handleUserInput = (event) => {
    setError(ERROR_TMPLT)
    setUser(event.target.value)
  }

  const handlePasswordInput = (event) => {
    setError(ERROR_TMPLT)
    setPassword(event.target.value)
  }

  const onLoginClick = async () => {
    setSigningIn(true)

    if (!patterns.usernameRegex.test(user) && !patterns.emailRegex.test(user)) {
      setError({
        user: 'Enter a valid user!',
        password: '',
      })
      setSigningIn(false)
      return;
    }
    let username = user
    if (patterns.emailRegex.test(user)) {
      const uidRequest = await fetch(`https://reg.${domain}/${username}/uid`)
      const uidResponse = await uidRequest.json()

      if (uidRequest.status !== 200) {
        setError({
          user: 'Invalid Username or Password',
          password: 'Invalid Username or Password',
        })
        setSigningIn(false)
        return
      }

      username = uidResponse.uid
    }

    if (!username.startsWith('d-')) {
      setError({
        user: 'Must Login with Doctor Account!',
        password: '',
      })
      setSigningIn(false)
      return
    }


    const authRequestPayload = {
      appId: "nushu-native-web",
      password: password,
      username: username,
    }

    const authRequest = await fetch(
      `https://${username}.${domain}/auth/login`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(authRequestPayload)
      }
    )
    
    if (authRequest.status >= 400) {
      setError({
        user: 'Invalid Username or Password',
        password: 'Invalid Username or Password',
      })
      setSigningIn(false)
      return
    }

    if (authRequest.status !== 200) {
      setError({
        user: 'Something went wrong',
        password: 'Something went wrong',
      })
      setSigningIn(false)
      return
    }
    
    const authResponse = await authRequest.json()
    const token = authResponse.token;
    
    // Run setup calls
    await fetch(
      `https://${username}.${domain}/`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(doctorSetupCalls)
      }
    )

    // App Access
    let access = undefined
    const appAccessQueryResult = await fetch(
      `https://${username}.${domain}/accesses`,
      {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
      }
    )
    .then((res) => res.json())
    .then(res => {
      return res.accesses.filter(access => access.deviceName === "web-dashboard")
    })
    if (appAccessQueryResult.length === 1) {
      access = appAccessQueryResult[0]
    } else {
      const appAccessBody = {
        name: "wba-" + generateRandomString(20),
        type: "app",
        deviceName: "web-dashboard",
        permissions: appPermissions.requestedPermissions,
        expireAfter: 2 * 24 * 60 * 60
      }

      const createAppAccessRequest = await fetch(
        `https://${username}.${domain}/accesses`,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization": token,
          },
          body: JSON.stringify(appAccessBody)
        }
      )

      const response = await createAppAccessRequest.json()
      access = response.access;
    }
    setDocEndpoint(access.apiEndpoint)
    setSigningIn(false)
    postLogin()
  }

  return (
  <Fragment>
    <Grid item xs={12}>
      <Box sx={{height: 65}}>
        <TextField
          value={user}
          onChange={handleUserInput}
          error={Boolean(error.user)}
          helperText={error.user ? error.user : null}
          label="Email or Username"
          variant="filled"
          id="email-textfield"
          sx={{ width: '100%' }}/>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box sx={{height: 65}}>
        <TextField
          value={password}
          onChange={handlePasswordInput}
          error={Boolean(error.password)}
          helperText={error.password ? error.password : null}
          label="Password"
          type="password"
          variant="filled"
          id="password-textfield"
          sx={{ width: '100%' }}/>
      </Box>
    </Grid>
    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 60 }}>
      <Button
        disabled={(!user || !password)}
        variant="contained"
        onClick={onLoginClick}
        id="authenticate-button"
        sx={{ width: '100%', height: '100%' }}>
          {(isSigningIn) ? (
            <CircularProgress color="secondary" size={20}/>
          ) : (
            <span>Log in</span>
          )}
      </Button>
    </Grid>
  </Fragment>

  );
};

MLoginForm.propTypes = {
    domain: PropTypes.string,
    postLogin: PropTypes.func
  };
