/**
 * Make Time String
 * Generates a object containing the time and unit of the passed time.
 * @param {number} timeInS the time in seconds
 * @return {Object} containing the time and unit, both as strings.
 */
export function makeTimeString(timeInS) {
  const res = {};
  if (timeInS < 60) {
    res.value = timeInS.toFixed(0);
    res.unit = 's';
  } else if (timeInS / 60 < 60) {
    const min = Math.floor(timeInS / 60).toString();
    let sec = Math.round(timeInS % 60).toString();
    if (sec.length < 2) {
      sec = '0' + sec;
    }
    res.value = `${min}:${sec}`;
    res.unit = 'min';
  } else if (timeInS / 60 ** 2 > 1) {
    const hrs = Math.floor(timeInS / 60 ** 2).toString();
    let min = Math.round((timeInS / 60 ** 2) % 60).toString();
    if (min.length < 2) {
      min = '0' + min;
    }
    res.value = `${hrs}:${min}`;
    res.unit = 'h';
  } else {
    res.value = timeInS.toFixed(0);
    res.unit = '?';
  }
  return res;
}

/**
 * Make Distance String
 * @param {number} distanceInM distance in meters
 * @return {Object} containing value and unit as strings.
 */
export function makeDistanceString(distanceInM) {
  const res = {};
  if (distanceInM < 1000) {
    if (distanceInM < 100) {
      res.value = distanceInM.toFixed(1);
      res.unit = 'm';
    } else {
      res.value = distanceInM.toFixed(0);
      res.unit = 'm';
    }
  } else {
    res.value = (distanceInM / 1000).toFixed(2);
    res.unit = 'km';
  }
  return res;
}

/**
 * Equivalent to sleep in python
 * @param {number} ms time to pause in ms
 * @return {Promise} a promise that is fullfilled after x ms
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const defaultDateFormat = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

export const makeDateString = (date, format = defaultDateFormat) => {
  const ds = date.toLocaleString('en-DE', format);
  return ds;
};

export const makeTimeOfDayString = (date) => {
  const hrs = date.getHours().toString();
  let min = date.getMinutes().toString();
  if (min.length < 2) min = '0' + min;
  return `${hrs}:${min}`;
};

export const dateTimeString = (dd) => {
  const ds = makeDateString(dd);
  const hrs = dd.getHours().toString();
  let min = dd.getMinutes().toString();
  if (min.length < 2) min = '0' + min;
  const t = hrs + ':' + min;

  return `${ds}  @  ${t}`;
};

export const loadPatientData = () => {
  const pd = JSON.parse(sessionStorage.getItem('patient'));

  /*
    Read all dates in a patientData file and convert them from string to
    date obj.
  */
  const dateIds = ['birthdate', 'firstAssessmentDate', 'lastAssessmentDate', 'regDate'];

  dateIds.forEach((id) => {
    pd[id] = new Date(pd[id]);
  });

  return pd;
};

export const savePatientData = (patient) => {
  sessionStorage.setItem('patient', JSON.stringify(patient))
}

export const loadDoctorData = () => {
  const dd = JSON.parse(sessionStorage.getItem('doctorData'));
  return dd;
};

export const loadAssessmentComment = () => {
  const ct = sessionStorage.getItem('assComment');
  return ct;
};

export const generateRandomString = (length) => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let rString = ""
  
  for (let i = 0; i < length; i++) {
    rString += characters.charAt(Math.floor(Math.random() * length));
  }
  return rString
}

export const generateUsername = (length = 8) => {
  return 'd-' + generateRandomString(length)
}

export const RGBtoHSV = (r, g, b) => {
  let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc;
  rabs = r / 255;
  gabs = g / 255;
  babs = b / 255;
  v = Math.max(rabs, gabs, babs),
  diff = v - Math.min(rabs, gabs, babs);
  diffc = c => (v - c) / 6 / diff + 1 / 2;
  if (diff == 0) {
      h = s = 0;
  } else {
      s = diff / v;
      rr = diffc(rabs);
      gg = diffc(gabs);
      bb = diffc(babs);

      if (rabs === v) {
          h = bb - gg;
      } else if (gabs === v) {
          h = (1 / 3) + rr - bb;
      } else if (babs === v) {
          h = (2 / 3) + gg - rr;
      }
      if (h < 0) {
          h += 1;
      }else if (h > 1) {
          h -= 1;
      }
  }
  return {
      h: h,
      s: s,
      v: v
  };
}


export function HSVtoRGB(h, s, v) {
  var r, g, b, i, f, p, q, t;
  if (arguments.length === 1) {
      s = h.s, v = h.v, h = h.h;
  }
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
      case 0: r = v, g = t, b = p; break;
      case 1: r = q, g = v, b = p; break;
      case 2: r = p, g = v, b = t; break;
      case 3: r = p, g = q, b = v; break;
      case 4: r = t, g = p, b = v; break;
      case 5: r = v, g = p, b = q; break;
  }
  return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
  };
}